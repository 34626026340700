// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import clsx from "clsx";
import React from "react";
import { ContentItem } from "types/content";
interface Props {
  addedClasses?: string;
  title?: string;
  children?: React.ReactNode;
  photoItem?: ContentItem;
}
function Figure({
  img,
  alt,
  caption
}: {
  img?: string;
  alt?: string;
  caption?: string | null;
}) {
  if (!img) return null;
  return <figure className="md:w-1/2 md:float-right md:ml-4 md:mt-3 md:mb-2" data-sentry-component="Figure" data-sentry-source-file="PostCard.tsx">
      {img && <img className="w-full" alt={alt} src={img} />}
      {caption && <div className="text-sm mt-1 text-gray-darker overflow-y-auto max-h-24">
          {caption}
        </div>}
    </figure>;
}
function Title({
  title
}: {
  title?: string;
}) {
  if (!title) return null;
  return <div className="font-bold text-xl mb-2" data-sentry-component="Title" data-sentry-source-file="PostCard.tsx">{title}</div>;
}
export default function PostCard(props: Props) {
  const {
    addedClasses,
    title,
    photoItem,
    children
  } = props;
  const photo = photoItem?.photo;
  return <section className={clsx("external-content w-full mb-4 shadow-lg px-6 py-4 bg-white", addedClasses)} data-sentry-component="PostCard" data-sentry-source-file="PostCard.tsx">
      <Title title={title} data-sentry-element="Title" data-sentry-source-file="PostCard.tsx" />
      <div className="overflow-auto">
        {photoItem && <Figure img={photo?.url} alt={photoItem.title || title} caption={photoItem.content} />}
        <div>{children}</div>
      </div>
    </section>;
}